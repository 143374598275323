export default {
  namespaced: true,
  state: {
    // Info
    redirectors: [],
    linkTo: "redirectors.redirector",
    btnLabel: "Novo",
    loading: true, //when request is call turn this to true
    itemsKey: "redirectors",
    title: "Redirecionadores",
    name: "Redirecionadores",
    groupName: "redirector",
    hasEdit: true,
    hasBtnHov: true,
    // API Get index
    get: {
      urn: 'user/index?group_key=["Redirector"]',
      response: "users",
    },
    get2: {
      urn: 'user/index?group_key=["RedirectorPremium"]',
      response: "users",
    },

    // API routes
    routes: [
      {
        key: "add",
        form: "base",
        title: "Add New Redirector",
        success: "New Redirector has been registered",
        urn: "auth/create",
        response: "users",
        method: "post",
        dispatch: true,
      },
    ],

    //dynamic: true,
    hasExtraField: true,

    fields: [
      { key: "notify", label: "Notificar", formatter: (value) => (value ? "Sim" : "Não") },
      { key: "name", label: "Nome" },
      { key: "email", label: "Email" },
      { key: "cpf", label: "CPF" },
      { key: "telephone", label: "Telefone" },
    ],
    form: {},
  },
  mutations: {
    set: (state, payload) => {
      state.redirectors = [
        ...state.redirectors,
        ...payload.filter(
          (a) => !state.redirectors.map((b) => b.id).includes(a.id)
        ),
      ];
      state.loading = false;
    },
    unshift: (state, payload) => {
      state.redirectors.unshift(payload);
    },
    swap: (state, payload) => {
      state.redirectors.forEach((element, index) => {
        if (element.id === payload.id) {
          state.redirectors.splice(index, 1, payload);
        }
      });
    },
    remove: (state, payload) => {
      state.redirectors = state.redirectors.filter((a) => a.id !== payload);
    },
  },
  actions: {
    init: (context, payload) => {
      context.commit("set", payload);
    },
    add: (context, payload) => {
      context.commit("unshift", payload);
    },
    edit: (context, payload) => {
      context.commit("swap", payload);
    },
    remove: (context, payload) => {
      context.commit("remove", payload);
    },
  },
};
