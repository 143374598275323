export default {
  namespaced: true,
  state: {
    title: "Redirecionadores",
    itemsKey: "redirectors",
    createPacket: true,
    hasExtraFields: true,
    groupName: "redirector",
    forms: {
      base: [
        { key: "name", label: "Nome completo", type: "text", col: "5" },
        {
          key: "telephone",
          label: "Telefone",
          type: "text",
          col: "3",
          mask: [
            "+# (###) ##-##-##",
            "+## (##) #####-####",
            "+## (##) ####-####",
            "+# ###-###-####",
          ],
        },
        { key: "email", label: "Email", type: "text", col: "4" },
        {
          key: "cpf",
          label: "CPF/CNPJ",
          type: "text",
          col: "3",
          mask: ["###.###.###-##", "##.###.###/####-##"],
        },
        {
          key: "notify",
          label: "Notificar",
          type: "select",
          col: "3",
          options: [
            {
              value: true,
              text: "Notificar",
            },
            {
              value: false,
              text: "Não notificar",
            },
          ],
        },
      ],
    },
  },
};
